form {
  min-height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

form input {
  padding: 0.5rem;
  font-size: 1.5rem;
  border: none;
  background: white;
  height: 55px;
  border-radius: 4px 0px 0px 4px;
  width: 50%;
}

form button {
  padding: 0.5rem;
  font-size: 1.8rem;
  color: white;
  background: #5797b7;
  cursor: pointer;
  transition: all 0.3s ease;
  height: 60px;
  margin-top: 22px;
}

form button:hover {
  background: #5797b7;
  color: #262a30;
}

.todo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.todo-list {
  min-width: 60%;
  list-style: none;
}

.todo {
  margin: 0.5rem;
  background: white;
  font-size: 1.5rem;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 1s ease;
  height: 52px;
  border-radius: 4px;
}
.filter-todo {
  padding: 1rem;
  border-radius: 4px;
}
.todo li {
  flex: 1;
}

.todo-button {
  margin-top: 0px;
}

.trash-btn,
.complete-btn {
  background: #ff6f47;
  color: white;
  border: none;
  padding: 1rem;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 0px;
}

.trash-btn:hover,
.complete-btn:hover {
  color: #262a30;
  border: none;
  padding: 1rem;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 0px;
}

.complete-btn {
  background: #5797b7;
}

.todo-item {
  padding: 0rem 0.5rem;
}

.fa-trash,
.fa-check {
  pointer-events: none;
}

.fall {
  transform: translateY(10rem) rotateZ(20deg);
  opacity: 0;
}

.completed {
  text-decoration: line-through;
  opacity: 0.5;
}

/*CUSTOM SELECTOR */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background-image: none;
}

/* Custom Select */
.select {
  margin: 1rem;
  position: relative;
  overflow: hidden;
}

select {
  color: black;
  /* font-family: "Poppins", sans-serif; */
  cursor: pointer;
  width: 12rem;
}
/* Arrow */
.select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  background: #5797b7;
  cursor: pointer;
  pointer-events: none;
  border-radius: 4px;
}
/* Transition */
.select:hover::after {
  color: #262a30;
}

.checklist-bottom-margin {
  margin-bottom: 50px;
}

@media only screen and (max-width: 1024px) {
  .checklist-container {
    position: relative;
    top: 110px;
  }

  header,
  form {
    min-height: 20vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 30px;
  }

  form input {
    width: 85%;
    height: 55px;
  }

  form button {
    color: white;
    background: #5797b7;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 22px;
  }

  form input,
  form button {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    margin-left: 1%;
    border: none;
    /* background: white; */
  }

  .select {
    margin: 0rem 0rem 3rem 0rem;
    position: relative;
    overflow: hidden;
  }

  .filter-todo {
    background-color: white;
    font-size: 0.9rem;
  }

  .todo {
    margin: 0.5rem;
    background: white;
    height: 52px;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 1s ease;
  }

  .todo-list {
    min-width: 100%;
    list-style: none;
  }

  .todo-item {
    /* padding: 0rem 0.5rem; */
    font-size: 1.3rem;
  }

  .trash-btn,
  .complete-btn {
    /* background: #ff6f47;
    color: white;
    border: none;
    padding: 1rem;
    cursor: pointer;
    font-size: 1rem; */
    margin-top: 0px;
  }
}
