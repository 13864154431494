.modal-content {
  height: 620px;
  overflow: auto;
}

.version-link {
  color: rgb(41, 21, 41);
  display: flex;
  position: absolute;
  padding-right: 18px;
  top: 90px;
  right: 0px;
  /* color: #212933; */
}

.version-link:hover {
  color: white;
}

@media only screen and (max-width: 1024px) {
  .version-link {
    top: 70px;
  }
}
