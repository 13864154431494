@import url(https://fonts.googleapis.com/css2?family=Libre+Barcode+128+Text&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oxanium:wght@400&display=swap);
.modal-content {
  height: 620px;
  overflow: auto;
}

.version-link {
  color: rgb(41, 21, 41);
  display: flex;
  position: absolute;
  padding-right: 18px;
  top: 90px;
  right: 0px;
  /* color: #212933; */
}

.version-link:hover {
  color: white;
}

@media only screen and (max-width: 1024px) {
  .version-link {
    top: 70px;
  }
}

.row {
  margin: 0;
}

.header {
  /* 242a31 */
  /* 252c33 */
  /* background-color: #212933; */
  background: linear-gradient(
    45deg,
    #000 1%,
    #1c2127 1%,
    #1c2127 49%,
    #000 49%,
    #000 51%,
    #1c2127 51%,
    #1c2127 99%,
    #000 99%
  );
  background-size: 4px 4px;
  background-position: 50px 50px;
  height: 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: fixed !important;
  padding-top: 10px;
  z-index: 2;
}

.logo {
  /* color: linear-gradient(120deg, #f6d365 0%, #fda085 100%); */
  color: rgb(65, 32, 65);
  /* color: #5797b7; */
  font-size: 6rem;
  margin-left: 10px;
}

.logo:hover {
  color: rgb(102, 50, 102);
  transition: 0.7s;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -ms-transition: 0.7s;
  -o-transition: 0.7s;
  transform: rotate(360deg);
}

.text-logo {
  position: absolute;
  display: flex;
  color: #fff;
  font-size: 5rem;
  left: 135px;
  top: 0px;
  font-family: "Libre Barcode 128 Text", cursive;
}

.text-logo,
a:hover {
  color: white;
  text-decoration: none;
}

span {
  margin-left: 16px;
}

.donate-button {
  position: absolute;
  display: flex;
  justify-content: left;
  align-items: center;
  bottom: 130px;
  margin: 0px 0px 20px 30px;
  z-index: 3;
}

button {
  background-color: white;
  border-radius: 4px;
  padding: 2px 4px 2px 4px;
}

@media only screen and (max-width: 1024px) {
  .header {
    background-color: #212933;
    height: 6rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: fixed !important;
    padding-top: 5px;
    padding-bottom: 5px;
    z-index: 2;
  }

  .logo {
    font-size: 5rem;
    margin-left: 20px;
  }

  .text-logo {
    display: none;
  }
}

.target-input {
  display: flex;
  position: absolute;
  padding-right: 18px;
  top: 30px;
  right: 0px;
}

@media only screen and (max-width: 1024px) {
  .target-input {
    display: flex;
    position: absolute;
    padding-right: 18px;
    top: 20px;
    right: 0px;
  }
}

.navbar {
  /* background-color: #212933; */
  /* 242a31 */
  /* 242b31 */
  /* 1a1f24 */
  /* 252c33 */
  background: linear-gradient(
    45deg,
    #000 1%,
    #1c2127 1%,
    #1c2127 49%,
    #000 49%,
    #000 51%,
    #1c2127 51%,
    #1c2127 99%,
    #000 99%
  );
  background-size: 4px 4px;
  background-position: 50px 50px;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 7rem;
  padding-top: 150px;
  padding-bottom: 30px;
  height: 100vh;
  left: 0;
  font-size: 2.5rem;
}

.navbar a {
  color: white;
}

.navbar a:hover {
  /* color: #5797b7; */
  color: rgb(102, 50, 102);
}

.donate-link {
  color: white;
  padding-top: 15vh;
}

@media only screen and (max-width: 1024px) {
  .navbar {
    display: flex;
    flex-direction: row;
    position: fixed;
    background-color: #212933;
    padding-top: 15px;
    bottom: 0px;
    width: 100vw;
    height: 5rem;
    font-size: 2rem;
    z-index: 1;
  }

  .navbar a {
    color: white;
  }

  .navbar a:hover {
    color: rgb(102, 50, 102);
  }

  .donate-link {
    padding-top: 0px;
  }

  .mobile-notes {
    display: block;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Oxanium", Arial, Helvetica, sans-serif;
  text-decoration: none;
}

body {
  background-color: #414d58;
  /* background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%); */
}

.container-fluid {
  padding: 0;
}

.col-sm-8 {
  padding: 0px;
}

.nomatch {
  display: flex;
  height: 100vh;
  padding-top: 60px;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
}

.App {
  text-align: center;
  padding-top: 60px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  font-size: 8em;
  color: rgb(65, 32, 65);
  /* z-index: 1; */
}

.nomatch-icon {
  height: 40vmin;
  pointer-events: none;
  font-size: 8em;
  z-index: 1;
  color: white;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #414d58; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 150px;
  font-size: 2rem;
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1024px) {
  .App-header {
    min-height: 60vh;
  }

  .col-sm-8 {
    padding: 15px;
  }

  .notes-container {
    display: none;
  }

  .accordion > .card > .card-header {
    padding: 10px !important;
  }
}

/* .btn-info {
  color: #fff;
  background-color: #5797b7;
  background-color: rgb(65, 32, 65);
  border-color: black;
}

.btn-info:hover {
  background-color: rgb(102, 50, 102);
  border-color: black;
} */

/* .btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: rgb(102, 50, 102);
  border-color: black;
} */

button.btn.btn-info {
  background-color: rgb(65, 32, 65);
  border-color: black;
}

button.btn.btn-info:hover {
  background-color: rgb(102, 50, 102);
  border-color: black;
  box-shadow: none;
}

.btn-info.focus,
.btn-info:focus {
  color: #fff;
  background-color: rgb(65, 32, 65);
  border-color: black;
  box-shadow: none;
}

.page-container {
  position: relative;
  top: 140px;
}

.container {
  padding: 0;
}

.page-header {
  /* background-image: linear-gradient(120deg, #212933 0%, #000000 100%); */
  background-image: linear-gradient(120deg, rgb(65, 32, 65) 0%, #000000 100%);

  color: white;
  letter-spacing: 2ch;
  justify-content: left;
  border-radius: 4px;
  padding: 10px 10px 10px 20px;
  font-size: 1.5rem;
}

.card {
  background-color: transparent !important;
  border: none;
  padding-bottom: 2px;
}

.card-body {
  background-color: transparent !important;
  color: white;
  padding: 0px;
  margin: 0px;
}

.accordion > .card > .card-header {
  background-color: #242e3a;
  /* background-image: linear-gradient(120deg, #242e3a 0%, #18181a 100%); */
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  font-size: 1.3rem;
  border: 1px solid black;
  border-radius: 4px;
}

.accordion {
  overflow-anchor: none;
  background-color: none;
  margin-bottom: 20px;
}

.mod-container {
  padding: 0 0 1.5rem 0;
  margin-top: 0.2rem;
  background-color: #202020;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.mod-description {
  padding: 1rem 1rem 0rem 1rem;
}

h4 {
  background-image: linear-gradient(120deg, rgb(65, 32, 65) 0%, #000000 100%);
  /* background-image: linear-gradient(120deg, #212933 0%, #000000 100%); */
  letter-spacing: 1ch;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 4px;
  font-size: 1.3rem;
}

.ctc-icon {
  display: flex;
  position: absolute;
  font-size: 1.5rem;
  right: 0;
  padding-right: 1rem;
  margin-bottom: 50px;
  cursor: pointer;
}

.ctc-icon:hover {
  color: #5797b7;
  /* transition-duration: 0.5s; */
}

.ctc-icon:active {
  color: #492727;
  /* transition-duration: 0.5s; */
}

.table-dark {
  color: #fff;
  background-color: #202020;
}

.break-all {
  white-space: pre-line;
}

/* .table-dark td {
    padding-right: 0.85rem;
  } */

.table thead th {
  vertical-align: bottom;
  border-bottom: none;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.03);
  border-color: none;
}

.table td,
.table th {
  padding: 1rem;
  vertical-align: left !important;
  border-top: none;
  border-bottom: none;
}

.sql-font-color {
  color: #5797b7;
}

.encryption-textarea {
  display: flex;
  background-color: #202020;
  width: 100%;
  height: 50vh;
  color: white;
  font-size: 1.2rem;
  padding: 10px 10px 10px 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1024px) {
  .page-container {
    position: relative;
    top: 110px;
  }

  .page-header {
    letter-spacing: 1ch;
  }

  .break-all {
    word-break: break-all;
  }
}

.notes-container {
  /* 15191f */
  /* background-color: #212933; */
  background: linear-gradient(
    45deg,
    #000 1%,
    #1c2127 1%,
    #1c2127 49%,
    #000 49%,
    #000 51%,
    #1c2127 51%,
    #1c2127 99%,
    #000 99%
  );
  background-size: 4px 4px;
  background-position: 50px 50px;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 24vw;
  padding: 121px 15px 0px 20px;
  height: 100vh;
  right: 0px;
}

/* .notepad {
  overflow-y: scroll;
} */

.notes-textarea {
  display: flex;
  background-color: #202020;
  width: 100%;
  height: 74vh;
  border-radius: 4px;
  color: white;
  font-size: 1rem;
  padding: 10px 10px 10px 10px;
  margin-top: 0px;
  margin-bottom: 20px;
  resize: none;
}

.notes-buttons {
  position: absolute;
  bottom: 0px;
}

/* .full {
  height: 100%;
  width: 100%;
} */

/* .notes-bottom-margin {
  margin-bottom: 50px;
} */

@media only screen and (max-width: 1024px) {
  .notes-container {
    display: none;
  }
}

form {
  min-height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

form input {
  padding: 0.5rem;
  font-size: 1.5rem;
  border: none;
  background: white;
  height: 55px;
  border-radius: 4px 0px 0px 4px;
  width: 50%;
}

form button {
  padding: 0.5rem;
  font-size: 1.8rem;
  color: white;
  background: #5797b7;
  cursor: pointer;
  transition: all 0.3s ease;
  height: 60px;
  margin-top: 22px;
}

form button:hover {
  background: #5797b7;
  color: #262a30;
}

.todo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.todo-list {
  min-width: 60%;
  list-style: none;
}

.todo {
  margin: 0.5rem;
  background: white;
  font-size: 1.5rem;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 1s ease;
  height: 52px;
  border-radius: 4px;
}
.filter-todo {
  padding: 1rem;
  border-radius: 4px;
}
.todo li {
  flex: 1 1;
}

.todo-button {
  margin-top: 0px;
}

.trash-btn,
.complete-btn {
  background: #ff6f47;
  color: white;
  border: none;
  padding: 1rem;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 0px;
}

.trash-btn:hover,
.complete-btn:hover {
  color: #262a30;
  border: none;
  padding: 1rem;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 0px;
}

.complete-btn {
  background: #5797b7;
}

.todo-item {
  padding: 0rem 0.5rem;
}

.fa-trash,
.fa-check {
  pointer-events: none;
}

.fall {
  transform: translateY(10rem) rotateZ(20deg);
  opacity: 0;
}

.completed {
  text-decoration: line-through;
  opacity: 0.5;
}

/*CUSTOM SELECTOR */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background-image: none;
}

/* Custom Select */
.select {
  margin: 1rem;
  position: relative;
  overflow: hidden;
}

select {
  color: black;
  /* font-family: "Poppins", sans-serif; */
  cursor: pointer;
  width: 12rem;
}
/* Arrow */
.select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  background: #5797b7;
  cursor: pointer;
  pointer-events: none;
  border-radius: 4px;
}
/* Transition */
.select:hover::after {
  color: #262a30;
}

.checklist-bottom-margin {
  margin-bottom: 50px;
}

@media only screen and (max-width: 1024px) {
  .checklist-container {
    position: relative;
    top: 110px;
  }

  header,
  form {
    min-height: 20vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 30px;
  }

  form input {
    width: 85%;
    height: 55px;
  }

  form button {
    color: white;
    background: #5797b7;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 22px;
  }

  form input,
  form button {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    margin-left: 1%;
    border: none;
    /* background: white; */
  }

  .select {
    margin: 0rem 0rem 3rem 0rem;
    position: relative;
    overflow: hidden;
  }

  .filter-todo {
    background-color: white;
    font-size: 0.9rem;
  }

  .todo {
    margin: 0.5rem;
    background: white;
    height: 52px;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 1s ease;
  }

  .todo-list {
    min-width: 100%;
    list-style: none;
  }

  .todo-item {
    /* padding: 0rem 0.5rem; */
    font-size: 1.3rem;
  }

  .trash-btn,
  .complete-btn {
    /* background: #ff6f47;
    color: white;
    border: none;
    padding: 1rem;
    cursor: pointer;
    font-size: 1rem; */
    margin-top: 0px;
  }
}

.flag-container {
  padding-top: 240px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 10em;
  padding-bottom: 40px;
  color: rgb(65, 32, 65);
}

.flag-submit-button {
  margin-bottom: 24px;
}

.flag-success {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  color: green;
}

@media only screen and (max-width: 1024px) {
  .flag-container {
    padding-top: 140px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 10em;
    padding-bottom: 40px;
    color: white;
  }

  .flag-check-mobile {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
  }
}

