@import url("https://fonts.googleapis.com/css2?family=Libre+Barcode+128+Text&display=swap");

.row {
  margin: 0;
}

.header {
  /* 242a31 */
  /* 252c33 */
  /* background-color: #212933; */
  background: linear-gradient(
    45deg,
    #000 1%,
    #1c2127 1%,
    #1c2127 49%,
    #000 49%,
    #000 51%,
    #1c2127 51%,
    #1c2127 99%,
    #000 99%
  );
  background-size: 4px 4px;
  background-position: 50px 50px;
  height: 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: fixed !important;
  padding-top: 10px;
  z-index: 2;
}

.logo {
  /* color: linear-gradient(120deg, #f6d365 0%, #fda085 100%); */
  color: rgb(65, 32, 65);
  /* color: #5797b7; */
  font-size: 6rem;
  margin-left: 10px;
}

.logo:hover {
  color: rgb(102, 50, 102);
  transition: 0.7s;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -ms-transition: 0.7s;
  -o-transition: 0.7s;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.text-logo {
  position: absolute;
  display: flex;
  color: #fff;
  font-size: 5rem;
  left: 135px;
  top: 0px;
  font-family: "Libre Barcode 128 Text", cursive;
}

.text-logo,
a:hover {
  color: white;
  text-decoration: none;
}

span {
  margin-left: 16px;
}

.donate-button {
  position: absolute;
  display: flex;
  justify-content: left;
  align-items: center;
  bottom: 130px;
  margin: 0px 0px 20px 30px;
  z-index: 3;
}

button {
  background-color: white;
  border-radius: 4px;
  padding: 2px 4px 2px 4px;
}

@media only screen and (max-width: 1024px) {
  .header {
    background-color: #212933;
    height: 6rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: fixed !important;
    padding-top: 5px;
    padding-bottom: 5px;
    z-index: 2;
  }

  .logo {
    font-size: 5rem;
    margin-left: 20px;
  }

  .text-logo {
    display: none;
  }
}
