@import url("https://fonts.googleapis.com/css2?family=Oxanium:wght@400&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Oxanium", Arial, Helvetica, sans-serif;
  text-decoration: none;
}

body {
  background-color: #414d58;
  /* background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%); */
}

.container-fluid {
  padding: 0;
}

.col-sm-8 {
  padding: 0px;
}

.nomatch {
  display: flex;
  height: 100vh;
  padding-top: 60px;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
}

.App {
  text-align: center;
  padding-top: 60px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  font-size: 8em;
  color: rgb(65, 32, 65);
  /* z-index: 1; */
}

.nomatch-icon {
  height: 40vmin;
  pointer-events: none;
  font-size: 8em;
  z-index: 1;
  color: white;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #414d58; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 150px;
  font-size: 2rem;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1024px) {
  .App-header {
    min-height: 60vh;
  }

  .col-sm-8 {
    padding: 15px;
  }

  .notes-container {
    display: none;
  }

  .accordion > .card > .card-header {
    padding: 10px !important;
  }
}

/* .btn-info {
  color: #fff;
  background-color: #5797b7;
  background-color: rgb(65, 32, 65);
  border-color: black;
}

.btn-info:hover {
  background-color: rgb(102, 50, 102);
  border-color: black;
} */

/* .btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: rgb(102, 50, 102);
  border-color: black;
} */

button.btn.btn-info {
  background-color: rgb(65, 32, 65);
  border-color: black;
}

button.btn.btn-info:hover {
  background-color: rgb(102, 50, 102);
  border-color: black;
  box-shadow: none;
}

.btn-info.focus,
.btn-info:focus {
  color: #fff;
  background-color: rgb(65, 32, 65);
  border-color: black;
  box-shadow: none;
}
