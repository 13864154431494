.flag-container {
  padding-top: 240px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 10em;
  padding-bottom: 40px;
  color: rgb(65, 32, 65);
}

.flag-submit-button {
  margin-bottom: 24px;
}

.flag-success {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  color: green;
}

@media only screen and (max-width: 1024px) {
  .flag-container {
    padding-top: 140px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 10em;
    padding-bottom: 40px;
    color: white;
  }

  .flag-check-mobile {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
  }
}
