.page-container {
  position: relative;
  top: 140px;
}

.container {
  padding: 0;
}

.page-header {
  /* background-image: linear-gradient(120deg, #212933 0%, #000000 100%); */
  background-image: linear-gradient(120deg, rgb(65, 32, 65) 0%, #000000 100%);

  color: white;
  letter-spacing: 2ch;
  justify-content: left;
  border-radius: 4px;
  padding: 10px 10px 10px 20px;
  font-size: 1.5rem;
}

.card {
  background-color: transparent !important;
  border: none;
  padding-bottom: 2px;
}

.card-body {
  background-color: transparent !important;
  color: white;
  padding: 0px;
  margin: 0px;
}

.accordion > .card > .card-header {
  background-color: #242e3a;
  /* background-image: linear-gradient(120deg, #242e3a 0%, #18181a 100%); */
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  font-size: 1.3rem;
  border: 1px solid black;
  border-radius: 4px;
}

.accordion {
  overflow-anchor: none;
  background-color: none;
  margin-bottom: 20px;
}

.mod-container {
  padding: 0 0 1.5rem 0;
  margin-top: 0.2rem;
  background-color: #202020;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.mod-description {
  padding: 1rem 1rem 0rem 1rem;
}

h4 {
  background-image: linear-gradient(120deg, rgb(65, 32, 65) 0%, #000000 100%);
  /* background-image: linear-gradient(120deg, #212933 0%, #000000 100%); */
  letter-spacing: 1ch;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 4px;
  font-size: 1.3rem;
}

.ctc-icon {
  display: flex;
  position: absolute;
  font-size: 1.5rem;
  right: 0;
  padding-right: 1rem;
  margin-bottom: 50px;
  cursor: pointer;
}

.ctc-icon:hover {
  color: #5797b7;
  /* transition-duration: 0.5s; */
}

.ctc-icon:active {
  color: #492727;
  /* transition-duration: 0.5s; */
}

.table-dark {
  color: #fff;
  background-color: #202020;
}

.break-all {
  white-space: pre-line;
}

/* .table-dark td {
    padding-right: 0.85rem;
  } */

.table thead th {
  vertical-align: bottom;
  border-bottom: none;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.03);
  border-color: none;
}

.table td,
.table th {
  padding: 1rem;
  vertical-align: left !important;
  border-top: none;
  border-bottom: none;
}

.sql-font-color {
  color: #5797b7;
}

.encryption-textarea {
  display: flex;
  background-color: #202020;
  width: 100%;
  height: 50vh;
  color: white;
  font-size: 1.2rem;
  padding: 10px 10px 10px 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1024px) {
  .page-container {
    position: relative;
    top: 110px;
  }

  .page-header {
    letter-spacing: 1ch;
  }

  .break-all {
    word-break: break-all;
  }
}
