.target-input {
  display: flex;
  position: absolute;
  padding-right: 18px;
  top: 30px;
  right: 0px;
}

@media only screen and (max-width: 1024px) {
  .target-input {
    display: flex;
    position: absolute;
    padding-right: 18px;
    top: 20px;
    right: 0px;
  }
}
