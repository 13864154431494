.notes-container {
  /* 15191f */
  /* background-color: #212933; */
  background: linear-gradient(
    45deg,
    #000 1%,
    #1c2127 1%,
    #1c2127 49%,
    #000 49%,
    #000 51%,
    #1c2127 51%,
    #1c2127 99%,
    #000 99%
  );
  background-size: 4px 4px;
  background-position: 50px 50px;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 24vw;
  padding: 121px 15px 0px 20px;
  height: 100vh;
  right: 0px;
}

/* .notepad {
  overflow-y: scroll;
} */

.notes-textarea {
  display: flex;
  background-color: #202020;
  width: 100%;
  height: 74vh;
  border-radius: 4px;
  color: white;
  font-size: 1rem;
  padding: 10px 10px 10px 10px;
  margin-top: 0px;
  margin-bottom: 20px;
  resize: none;
}

.notes-buttons {
  position: absolute;
  bottom: 0px;
}

/* .full {
  height: 100%;
  width: 100%;
} */

/* .notes-bottom-margin {
  margin-bottom: 50px;
} */

@media only screen and (max-width: 1024px) {
  .notes-container {
    display: none;
  }
}
