.navbar {
  /* background-color: #212933; */
  /* 242a31 */
  /* 242b31 */
  /* 1a1f24 */
  /* 252c33 */
  background: linear-gradient(
    45deg,
    #000 1%,
    #1c2127 1%,
    #1c2127 49%,
    #000 49%,
    #000 51%,
    #1c2127 51%,
    #1c2127 99%,
    #000 99%
  );
  background-size: 4px 4px;
  background-position: 50px 50px;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 7rem;
  padding-top: 150px;
  padding-bottom: 30px;
  height: 100vh;
  left: 0;
  font-size: 2.5rem;
}

.navbar a {
  color: white;
}

.navbar a:hover {
  /* color: #5797b7; */
  color: rgb(102, 50, 102);
}

.donate-link {
  color: white;
  padding-top: 15vh;
}

@media only screen and (max-width: 1024px) {
  .navbar {
    display: flex;
    flex-direction: row;
    position: fixed;
    background-color: #212933;
    padding-top: 15px;
    bottom: 0px;
    width: 100vw;
    height: 5rem;
    font-size: 2rem;
    z-index: 1;
  }

  .navbar a {
    color: white;
  }

  .navbar a:hover {
    color: rgb(102, 50, 102);
  }

  .donate-link {
    padding-top: 0px;
  }

  .mobile-notes {
    display: block;
  }
}
